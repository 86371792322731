import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import useRegisterInviteCode from '../../screens/profile/useRegisterInviteCode';
import { useAuth} from '../../../hooks';
import {execRegisterInviteCode, validateInviteCode} from '../../../utils/inviteCode';
import { saveInviteCode } from '../../../utils';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { B4SpaceVertical } from '../../../design/atomic/layout';
import { B4TextMedium } from '../../../design/atomic/text';
import { B4Button } from '../../../design/atomic/button';
import i18n from '../../../_i18n/i18n';
import { B4ControllerTextInput } from '../../../design/atomic/input/text';
import { B4Color } from '../../../design/atomic/consts';

type FormData = {
  code: string
}

export const InviteCodeForm = ({successCallback }) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [registerInviteCode] = useRegisterInviteCode();
  const { data: auth } = useAuth();
  const navigate = useNavigate();
  
  const {
    handleSubmit,
    control,
    setError,
  } = useForm<FormData>();
  
  const submit = async values => {
    if (auth?.isLoggedIn) {
      const data = await execRegisterInviteCode(registerInviteCode, values.code);
      if (data) {
        successCallback(data.id, values.code);
      } else {
        setError('code', { type: 'manual', message: t('txtInvalidCode') });
      }
    } else {
      const tenant = await validateInviteCode(client, values.code);
      if (tenant?.id) {
        saveInviteCode(values.code);
        if (tenant?.language) {
          await i18n.changeLanguage(tenant?.language)
        }
        successCallback(tenant?.id, values.code);
      } else {
        setError('code', { type: 'manual', message: t('txtInvalidCode') });
      }
    }
  };

  return <>
    <form onSubmit={handleSubmit(submit)}>
      <B4SpaceVertical>
        <B4TextMedium>{t('txtPleaseEnterYourInviteCode')}</B4TextMedium>
        <B4ControllerTextInput type='password' name='code' label={t('txtByInvitationOnly')} control={control} required={true} autoFocus />
        <B4Button onClick={() => handleSubmit(submit)()}>{t('txtContinue')}</B4Button>
        <B4Button onClick={() => navigate('/landing')} color={B4Color.GREY}>{t('txtCancel')}</B4Button>
      </B4SpaceVertical>
    </form>
  </>
}