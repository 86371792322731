import { Trans } from "react-i18next";
import { B4Bubble } from "../../design/atomic/bubble";
import { B4Layout, B4SpaceVertical } from "../../design/atomic/layout";
import { B4Link, B4TextTiny } from "../../design/atomic/text";
import { PRIVACY_POLICY_URL, TERMS_URL } from "../../constants";
import logo from '../../images/brand.webp';
import { B4PoweredBy, B4StdHeader } from "./standard";
import { useTenantMinimal } from "../../hooks";
import clsx from "clsx";

const A = ({children, to}) => (
  <B4Link to={to} target='_blank' rel='noopener noreferrer'>{children}</B4Link>
);

const Terms = () => (
  <B4TextTiny className='text-center'>
    <Trans i18nKey='agreeLabel'>
      <A to={TERMS_URL}>Terms</A> - <A to={PRIVACY_POLICY_URL}>Privacy Policy</A>
    </Trans>
  </B4TextTiny>
)

export const B4LoginLogo = ({className, src = logo}) => (
  <div className='flex justify-center'>
    <img className={clsx(className, 'object-contain')} src={src} alt='' />
  </div>
)

export const B4LoginLayout = ({children = null, showLogoAndTeaser = false}) => {
  const tenant = useTenantMinimal()

  return (
    <>
      <B4Layout vCentered header={<B4StdHeader tenant={tenant} />}>
        {children && <B4Bubble><B4SpaceVertical>
          {children}
          { showLogoAndTeaser &&
            <B4PoweredBy>
              <Terms />
            </B4PoweredBy>
          }
        </B4SpaceVertical>
      </B4Bubble>}
    </B4Layout>
  </>
)}