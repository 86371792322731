import {ApolloClient, InMemoryCache, gql, from} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { removeAuth, getAuth, getRoom } from '../../utils';
import { getAuthHeader, getBasePath } from '../../utils/api';
import i18n from '../../_i18n/i18n';


export const genApolloClient = () => {
  const uploadLink = createUploadLink({
    uri: getBasePath() + '/graphql',
  });

  const authLink = setContext((_, {headers}) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: getAuthHeader(),
      },
    };
  });

  const tenantLink = setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
        'x-tenant': getRoom(),
        'x-applang': i18n.language,
      },
    };
  });

  const isAuthenticationError = ({ message }) => {
    return message === 'Unauthenticated.';
  };

  const logout = () => {
    const {access_token} = getAuth();
    if (access_token) {
      removeAuth();
      window.location.pathname = '/';
    }
  };

  const logoutLink = onError(({graphQLErrors}) => {
    graphQLErrors?.forEach(graphQLError => {
      if (isAuthenticationError(graphQLError)) logout();
    });
  });

  const errorLink = onError(({response, graphQLErrors, networkError}) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({message}) => {
        console.log(`[GraphQL error]: Message: ${message}`);
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: from([errorLink, logoutLink, authLink, tenantLink, uploadLink]),
    cache: new InMemoryCache(),
    resolvers: {
      Query: {
        isLoggedIn: () => !!localStorage.getItem('access_token'),
      },
    },
    typeDefs: gql`
      enum ReportableType {
        QUESTION
        ANSWER
        User
      }
      enum QualityControl {
        ByCommunity
        ByAdministrator
      }
      enum LoginMethod {
        email
        email_otp
        guest
        google
        apple
      }
      enum MandatoryUserInfo {
        gender
        age
        region
        education
        country
        town
        share_email
      }
      enum UserType {
        Admin
        User
      }
    `,
  });

  return client;
}
