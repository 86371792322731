import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const CREATE_MATCH_MUTATION = gql`
  mutation($answer_id: ID!) {
    createMatch(answer_id: $answer_id) {
      success
    }
  }
`;

const useCreateMatch = ({ variables }) => {
  return useMutation(CREATE_MATCH_MUTATION, { variables });
};

export default useCreateMatch;
