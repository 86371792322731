import { useQuery, gql } from '@apollo/client';

const TOWNS_QUERY = gql`
  query {
    towns {
      id
      code
      name
    }
  }
`;

const useTowns = () => {
  return useQuery(TOWNS_QUERY);
};

export default useTowns;
