import { gql, useQuery } from '@apollo/client';

const TENANT_QUERY = gql`
  query($invite_code: String!) {
    validateInviteCode(invite_code: $invite_code) {
      id
      login_methods
      language
    }
  }
`;

const SIGNUP_OPTIONS = gql`
  query {
    signupOptions {
      login_methods
      mandatory_user_info
    }
  }
`

export const validateInviteCode = async (apolloClient, inviteCode) => {
  const { data } = await apolloClient.query({
    query: TENANT_QUERY,
    variables: { invite_code: inviteCode },
  });
  return data?.validateInviteCode;
};

export const useSignupOptions = (options = {}) => {
  return useQuery(SIGNUP_OPTIONS, options);
}


export const execRegisterInviteCode = async (registerInviteCode, inviteCode) => {
  const { data } = await registerInviteCode({
    variables: { invite_code: inviteCode },
  });
  return data?.registerInviteCode;
}