import { useMutation } from '@apollo/client';
import { graphql } from '../../gql';

const ANSWER_RESTORE = graphql(`
  mutation restoreAnswer($id: ID!) {
    restoreAnswer(id: $id) {
      id
      deleted_at
    }
  }
`)

const useRestoreAnswer = () => {
  return useMutation(ANSWER_RESTORE);
};

export default useRestoreAnswer;
