import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {REFETCH_CONVERSATIONS} from './refetches';

const QUESTION_CREATE = gql`
  mutation($question: String!, $start_at: Date, $end_at: Date, $tenant_id: ID!, $user_id: ID!) {
    CreateConversation(question: $question, start_at: $start_at, end_at: $end_at, tenant_id: $tenant_id, user_id: $user_id) {
      id
      question

      start_at
      end_at
      user {
        id
      }
    }
  }
`;

const useCreateQuestion = () => {
  return useMutation(QUESTION_CREATE, {
    awaitRefetchQueries: true,
    refetchQueries: REFETCH_CONVERSATIONS,
  });
};

export default useCreateQuestion;
