/** @jsxImportSource @emotion/react */

import clsx from "clsx";
import { ReactNode, useContext } from "react";
import { B4LoadingContext } from "../../utils/b4Context";
import { B4BackgroundContext, B4BackgroundType } from "./utils";
import { css, keyframes } from "@emotion/react";
import { B4Color } from "./consts";
import { B4TextBig, B4TextMedium, B4TextSubmedium } from "./text";


export const B4Header = ({children}) => {
  const { bgType } = useContext(B4BackgroundContext)
  const className = clsx({
    'shadow-b4-bg-dark': bgType === B4BackgroundType.DARK,
  }, 'flex justify-center sticky top-0 z-20 bg-white shadow')

  return (
    <div className={className}>
      <div className={clsx('max-w-full h-b4-header flex justify-between items-center px-b4-std w-b4-max')}>{children}</div>
    </div>
  )
}

export const B4Footer = ({children}) => (
  <div className="-mx-b4-std -mb-b4-std mt-b4-std sticky bottom-0">
    <div className="flex justify-center">
      <div className="max-w-full w-b4-max">{children}</div>
    </div>
  </div>
)

const B4Loading = ({loading}) => {
  const ctContext = useContext(B4LoadingContext);

  const loadingMove = keyframes`
    from {
      transform: translateX(-50%);
    }

    to {
      transform: translateX(100%);
    }
  `

  return (
    <div className={clsx('fixed top-0 left-0 right-0 z-[100]')} style={{
      opacity: (loading || ctContext.loading) ? 1 : 0,
      transition: 'opacity .25s ease-in-out'
    }}>
      <div css={css`
        animation: ${loadingMove} 2000ms linear infinite;
      `}>
        <div className="bg-gradient-to-r from-b4-question to-b4-secondary h-1 w-1/2" />
      </div>
    </div>
  )
}

export const B4Layout = ({children = null, header = null, padding = true, screenBig = false, vCentered = false, loading = false}) => {
  return (<>
    <B4Loading loading={loading}/>
    <div className="min-h-full flex flex-col">
      {header}
      <div className={clsx('flex justify-center grow', {
        'items-center': vCentered
      })}>
        <div className={clsx('max-w-full', {
          'w-b4-max': !screenBig,
          'w-b4-max-2x': screenBig,
          'p-b4-std': padding
        })
        }>{
            children
        }</div>
      </div>
    </div>
  </>)
}

export const B4ScreenPartSmall = ({children, className, innerClassName}: {children: ReactNode, className?: string, innerClassName?: string}) => (
  <div className={clsx('flex justify-center', className)}><div className={clsx('w-b4-max-p-std max-w-full', innerClassName)}>{children}</div></div>
)

export const B4SpaceHorizontal = ({children, onClick = null, className = null}) => (
  <div className={clsx('flex gap-x-b4-std', className)} onClick={onClick}>{children}</div>
)

export enum B4NormalDistType {
  EDGES_INCLUDED,
  EDGES_EXCLUDED,
  NO_EDGES
}

export const B4NormalDistHorizontal = ({elements, className = null, type = B4NormalDistType.EDGES_EXCLUDED}: {elements: ReactNode[], className?: string, type?: B4NormalDistType}) => (
  <div className={clsx('flex', className)}>
    { type !== B4NormalDistType.NO_EDGES &&
      <div className="grow basis-0 flex">
        { type === B4NormalDistType.EDGES_INCLUDED && <div className="-translate-x-1/2">{elements[0]}</div> }
      </div>
    }
    {
      (type === B4NormalDistType.EDGES_INCLUDED ? elements.slice(1, -1): elements).map((element, i) => (
        <div key={i} className="grow-[2] min-w-0 basis-0 flex justify-center">{element}</div>
      ))
    }
    { type !== B4NormalDistType.NO_EDGES &&
      <div className="grow basis-0 flex justify-end min-w-0">
        { type === B4NormalDistType.EDGES_INCLUDED && <div className="translate-x-1/2">{elements[elements.length - 1]}</div> }
      </div>
    }
  </div>
)

export const B4NormalDistVerticalEdgesIncluded = ({elements, className = null}: {elements: ReactNode[], className?: string}) => (
  <div className={clsx('flex flex-col', className)}>
    <div className="grow basis-0 flex flex-col">
      <div className="-translate-y-1/2 flex justify-end">{elements[0]}</div>
    </div>
    {
      elements.slice(1, -1).map((element, i) => (
        <div key={i} className="grow-[2] min-w-0 basis-0 flex flex-col justify-center">{element}</div>
      ))
    }
    <div className="grow basis-0 flex flex-col justify-end min-w-0">
      <div className="translate-y-1/2 flex justify-end">{elements[elements.length - 1]}</div>
    </div>
  </div>
)

export const B4SpaceVertical = ({children, className = null}) => (
  <div className={clsx(
    'flex flex-col gap-y-b4-std',
    '[&>*+h1]:mt-b4-std',
    '[&>*+h2]:mt-b4-std-1/2 [&>*+h3]:mt-b4-std-1/2',
    className)}>{children}</div>
)

const genRulerColor = (color: B4Color) => ({
  'bg-gray-300': color === B4Color.GREY,
  'bg-b4-primary': color === B4Color.BLUE_DARK,
  'bg-b4-secondary': color === B4Color.GREEN,

})

export const B4RulerHorizontal = ({className = null, color = B4Color.GREY, thin = false}) => (
  <hr className={clsx('border-0', genRulerColor(color), {
    'h-b4-std-1/8': !thin,
    'h-b4-std-1/16': thin
  }, className)} />
)

export const B4RulerVertical = ({className = null, color = B4Color.GREY, thin = false}) => (
  <div className={clsx(genRulerColor(color), {
    'w-b4-std-1/8': !thin,
    'w-b4-std-1/16': thin
  }, className)} />
)

export const B4BackgroundDark = () => (
  <div className="fixed inset-0 z-[-2] bg-b4-bg-dark">
  </div>
)

export const B4BackgroundLight = () => (
  <div className="fixed inset-0 z-[-2] bg-b4-bg-light">
  </div>
)

export const B4ScreenCorner = ({children}) => (
  <div className="fixed bottom-0 left-0 right-0 flex justify-center z-10">
    <div className="max-w-full w-b4-max flex justify-end p-b4-std">
      { children }
    </div>
  </div>
)

export const B4H1 = ({html, className = null}) => (
  <B4TextBig as="h1" className={clsx('text-center', className)} color={B4Color.GREEN} html={html} />
)

export const B4H2 = ({html, className = null}) => (
  <B4TextMedium as="h2" className={clsx('text-center', className)} color={B4Color.GREEN} html={html} />
)

export const B4H3 = ({html, className = null}) => (
  <B4TextSubmedium as="h3" className={clsx('text-center', className)} color={B4Color.GREEN} html={html} />
)