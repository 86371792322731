import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { ME_QUERY } from '../queries/useMe';

const UPDATE_PHONE_NUMBER = gql`
  mutation($phone_number: String!, $verification_code: String) {
    updatePhoneNumber(
      phone_number: $phone_number
      verification_code: $verification_code
    ) {
      status
    }
  }
`;

const useUpdatePhoneNumber = () => {
  return useMutation(UPDATE_PHONE_NUMBER, {
    refetchQueries: [
      {
        query: ME_QUERY,
      },
    ],
  });
};

export default useUpdatePhoneNumber;
