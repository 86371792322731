import { useQuery, gql } from '@apollo/client';

export const APPROVAL_ANSWER_QUERY = gql`
  query($conversation_id: ID!) {
    conversation(id: $conversation_id) {
      id,
      question,
      created_at,
      user {
        avatar,
        name
      },
      answers(where: {column:APPROVED, value: false}) {
        id,
        answer,
        approved
      }
    }
  }
`;

const useApprovalAnswers = (options = {}) => {
  return useQuery(APPROVAL_ANSWER_QUERY, options);
};

export default useApprovalAnswers;
