import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

export const TENANT_MINIMAL_QUERY = gql`
  query {
    tenantMinimal {
      id
      image
      about_heading
      about_body
      teaser
      customer_name
    }
  }
`;

const useTenantMinimal = (options = {}) => {
  const { data } = useQuery(TENANT_MINIMAL_QUERY, options);
  const tenant = useMemo(() => {
    if (!data) return {};
    return data.tenantMinimal;
  }, [data]);

  return tenant;
};

export default useTenantMinimal;
