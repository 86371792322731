import { useQuery, gql } from '@apollo/client';
import useAuth from './useAuth';

export const ME_QUERY = gql`
  query {
    me {
      id
      name
      age
      gender
      avatar
      country
      town {
        id
        code
        name
      }
      language
      translation_enabled
      website
      email
      phone
      is_innovator
      role
      verified
      about
      feedback_collected
      profile_complete
      missing_fields_voter
      missing_fields_innovator
      conversation_count
      has_email
      notifications_push
      notifications_email
      login_method
      type
      welcome_shown
      current_tenant_id
      tenants {
        id
        about_heading
        quality_control
        invite_code
        image
        expired
      }
      conversations {
        id
      }
    }
  }
`;

const useMe = (options = {}) => {
  const auth = useAuth();

  return useQuery(ME_QUERY, {
    skip: !auth.data?.isLoggedIn,
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export default useMe;