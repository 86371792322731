import { useTranslation } from "react-i18next";
import { B4Button, B4ButtonOutlined, B4IconButton } from "./button";
import { B4Header, B4SpaceHorizontal, B4SpaceVertical } from "./layout";
import { MdArrowUpward } from "react-icons/md";
import { useNavigate } from "react-router";
import { IoHelpOutline } from "react-icons/io5";
import { ReactNode, useEffect, useState } from "react";
import { B4Modal, B4ModalTitle } from "./modal";
import { B4Bubble } from "./bubble";
import { B4Text, B4TextSmall } from "./text";
import { B4PoweredBy, B4StdHeaderBtns } from "../../components/common/standard";
import { useRoomNavigate } from "../../utils";
import { GrHomeRounded } from "react-icons/gr";
import clsx from "clsx";
import { B4CheckBox } from "./input/checkbox";
import { B4Color } from "./consts";

export const B4HeaderBackBtn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <B4ButtonOutlined full={false} onClick={() => navigate(-1)} 
        icon={<MdArrowUpward className="text-base" />} color={B4Color.BLUE_DARK}
        className="px-4">
      <B4TextSmall>{t('txtGoBack')}</B4TextSmall>
    </B4ButtonOutlined>
  )
}


export const B4HeaderLight = ({children = null, headerBtns = null}) => {
  const navigate = useRoomNavigate()
  return (
    <B4Header>
      <div className="grow basis-0">
        <B4IconButton  big={true} onClick={() => navigate('/')}><GrHomeRounded className="text-b4-primary" /></B4IconButton>
      </div>
      { children }
      <div className="grow basis-0 flex justify-end">
        <B4StdHeaderBtns>{headerBtns}</B4StdHeaderBtns>
      </div>
    </B4Header>
  )
}

interface B4HeaderModalProps {
  initialOpen?: boolean,
  onClose?: () => void,
  children: ReactNode,
  showPoweredBy?: boolean,
  icon: ReactNode,
  title?: string,
  btn?: string,
  btnColor?: B4Color,
  suffix?: ReactNode
}
export const B4HeaderModal = ({icon, onClose = null, initialOpen = false, children, title = null, btn, btnColor = B4Color.BLUE_DARK, suffix = null}: B4HeaderModalProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (initialOpen) setOpen(true)
  }, [initialOpen])

  const doClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  return (<div>
    <B4ButtonOutlined square color={btnColor} onClick={() => setOpen(true)} full={false}>{icon}</B4ButtonOutlined>
    <B4Modal open={open} onClose={doClose}>
      <B4Bubble>
        <B4SpaceVertical>
          {title && <B4ModalTitle text={title} /> }
          {children}
          <div className="sticky bottom-0">
            <B4Button onClick={doClose}>{btn || t('txtOk')}</B4Button>
          </div>
          {suffix}
          <B4PoweredBy/>
        </B4SpaceVertical>
      </B4Bubble>
    </B4Modal>
  </div>)
}

interface B4HeaderHelpProps {
  title?: string,
  html: string,
  btn?: string,
  initialOpen?: boolean,
  onClose?: (boolean) => void,
  showPoweredBy?: boolean
}
export const B4HeaderHelp = ({html, showPoweredBy, initialOpen, onClose, ...props}: B4HeaderHelpProps) => {
  const { t } = useTranslation()
  const [doNotShowAgain, setDoNotShowAgain] = useState(false)
  const suffix = initialOpen ? <B4CheckBox label={t('txtDoNotShowAnymore')} value={doNotShowAgain} onClick={() => setDoNotShowAgain(!doNotShowAgain)} /> : null
  return (
    <B4HeaderModal icon={<IoHelpOutline className="text-base" />} showPoweredBy={showPoweredBy} initialOpen={initialOpen} suffix={suffix} onClose={() => onClose && onClose(doNotShowAgain)} {...props}>
      <B4Text html={html} />
    </B4HeaderModal>
  )
}

export interface B4BreadcrumData {
  text?: string, link?: string
}

export interface B4HeaderSubBreadcrumbProps {
  items: B4BreadcrumData[]
}

export const B4HeaderSub = ({left = null, middle = null, right = null, className = null, languageBtn = true}) => {
  const middleOnly = !left && !right
  return (
    <B4SpaceHorizontal className={clsx(className, "justify-center")}>
      { !middleOnly && <div className="grow basis-0 flex items-start space-x-2">{ left }</div> }
      { middle }
      { !middleOnly && <div className="grow basis-0 flex justify-end items-start space-x-2">{ right }</div> }
    </B4SpaceHorizontal>
  )
}