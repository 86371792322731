/** @jsxImportSource @emotion/react */

import clsx from "clsx"
import { Component, ReactNode, memo } from "react"
import { ctLinkifyText } from "../../utils/ctLinkify"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { B4Color } from "./consts"

export interface B4TextProps {
  children?: ReactNode,
  html?: string,
  color?: B4Color,
  className?: string,
  label?: string,
  sup?: string|number,
  style?: React.CSSProperties,
  noWordBreak?: boolean,
  as?: 'p' | 'h1' | 'h2' | 'h3'
}

export const B4_TEXT_HTML_CSS = css`
  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  },
  * + h1 {
    margin-top: 1rem;
  },
  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
  },
  h3 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
  },
  * + h2, * + h3 {
    margin-top: 0.5rem;
  },
  h1, h2, h3, b {
    font-weight: 600;
  }
  p + p, ul + p, p + ul, ul + ul {
    margin-top: 0.25rem;
  },
  p {
    min-height: 1rem;
  }
  ul {
    padding-left: 0.938rem;
  }
  ul li {
    list-style-type: none;
    position: relative; /* Needed to position the custom bullet */
  }

  ul li::before {
    content: "•"; /* The dot symbol */
    position: absolute;
    left: -0.938rem; /* Adjust this value based on your needs */
    font-size: 20px; /* Adjust the size of the dot */
  }
`

export const B4TextSup  = ({children, color, big = false, className = null}: {children?: ReactNode, color?: B4Color, big?: boolean, className?: string}) => (
  <div className={clsx(className,'rounded-full flex justify-center items-center px-b4-std-1/2', {
    'text-xs h-4 min-w-4': !big,
    'text-base h-7 min-w-7': big,
    'bg-b4-primary text-white': color === B4Color.BLUE_DARK,
    'bg-b4-title text-white': color === B4Color.BLUE,
    'bg-white text-b4-primary': color === B4Color.WHITE,
    'bg-b4-secondary text-b4-primary': color === B4Color.GREEN,
    'bg-b4-red text-white': color === B4Color.RED,
    'bg-gray-600 text-white': color === B4Color.GREY,
    'bg-b4-orange text-white': color === B4Color.ORANGE,
  })}>
    {children}
  </div>
)


const B4TextBase = ({children = null, className, color = B4Color.BLUE_DARK, label = null, sup = null, html = null, style = null, noWordBreak = false, as: Component = 'div' as keyof JSX.IntrinsicElements}) => {
  const colorClsx = {
    'text-b4-primary': color === B4Color.BLUE_DARK,
    'text-b4-title': color === B4Color.BLUE,
    'text-b4-question': color === B4Color.BLUE_LIGHT,
    'text-white': color === B4Color.WHITE,
    'text-b4-secondary': color === B4Color.GREEN,
    'text-b4-red': color === B4Color.RED,
    'text-gray-600': color === B4Color.GREY,
    'text-b4-orange': color === B4Color.ORANGE,
  }


  const textElement = <Component
    className={clsx(colorClsx, className, html ? B4_TEXT_HTML_CSS : null)}
    style={{
      wordBreak: noWordBreak? undefined : 'break-word',
      ...style
    }}
    css={html ? B4_TEXT_HTML_CSS : null}
    dangerouslySetInnerHTML={html ? { __html: ctLinkifyText(html) } : null} 
    >
      {children}
  </Component>

  if (label) {
    return (
      <div className="space-y-1">
        { label && <div className={clsx(colorClsx, 'text-sm')}>{label}</div> }
        { textElement}
      </div>
    )
  }

  if (sup !== null) {
    return (
      <div className="flex space-x-b4-std-1/4">
        { textElement}
        <B4TextSup color={color}>{sup}</B4TextSup>
      </div>
    )
  }

  return textElement
}

const B4TextBaseTitle = ({className, color = B4Color.BLUE, ...props}: B4TextProps) => (
  <B4TextBase className={className} color={color} {...props} />
)

export const B4TextBig = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-2xl', className)} {...props} />
))

export const B4TextHuge = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-4xl font-semibold', className)} {...props} />
))

export const B4Text = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-base', className)} {...props} />
))

export const B4TextEmp = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-base font-semibold', className)} {...props} />
))

export const B4TextSubmedium = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-lg', className)} {...props} />
))

export const B4TextMedium = memo(({className, ...props}: B4TextProps) => (
  <B4TextBaseTitle className={clsx('text-xl', className)} {...props} />
))

export const B4TextSmall = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-sm', className)} {...props} />
))

export const B4TextTiny = memo(({className, ...props}: B4TextProps) => (
  <B4TextBase className={clsx('text-xs', className)} {...props} />
))

export const B4TextSmallArrowed = ({color, ...props}: {children?: ReactNode, html?: string, color?: B4Color}) => (
  <div className="flex space-x-b4-std-1/2">
    <B4TextSmall html="→" color={color} />
    <B4TextSmall color={color} {...props} />
  </div>
)

export interface B4LinkProps {
  children?: React.ReactNode,
  className?: string,
  innerClassName?: string,
  to: string,
  color?: B4Color,
  target?: string,
  rel?: string
}
  
export const B4Link = memo(({children, className = null, to, target = null, rel = null, ...props}: B4LinkProps) => (
  <B4TextBase className={clsx('underline inline', className)} {...props}><Link to={to} target={target} rel={rel}>{children}</Link></B4TextBase>
))