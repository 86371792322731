import { useEffect, useState } from "react";
import { B4Header, B4RulerHorizontal, B4SpaceHorizontal, B4SpaceVertical } from "../../design/atomic/layout";
import { B4Text, B4TextMedium, B4TextTiny } from "../../design/atomic/text";
import { B4ButtonOutlined, B4ButtonOutlinedBorder, B4IconButton } from "../../design/atomic/button";
import { BiMenuAltLeft } from "react-icons/bi";
import { BsMegaphoneFill } from "react-icons/bs";
import { B4NavItem, B4Navigation } from "../../design/atomic/navigation";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdLogout, MdOutlineExpandMore, MdSettings } from "react-icons/md";
import { TfiWorld } from "react-icons/tfi";
import { useAuth, useMe, useTenant } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import i18n from "../../_i18n/i18n";
import { useLogout } from "../screens/profile/edit/hooks";
import { useUpdateUser } from '../screens/profile/edit/hooks';
import { B4ModalCreate, B4ModalCreateState } from "../../design/atomic/modal";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSendFeedback } from "../feedback/hooks";
import { B4ControllerTextInput } from "../../design/atomic/input/text";
import logo from '../../images/brand.webp';
import clsx from "clsx";
import { sortBy } from "lodash";
import useTenants from "../../hooks/queries/useTenants";
import { B4Color } from "../../design/atomic/consts";
import { GrHomeRounded } from "react-icons/gr";

const LANGUAGES = [
  {
    label: 'txtGerman',
    value: 'de',
  },
  {
    label: 'txtEnglish',
    value: 'en',
  },
  {
    label: 'txtFrench',
    value: 'fr',
  },
  {
    label: 'txtItalian',
    value: 'it',
  },
  {
    label: 'txtPortuguese',
    value: 'pt',
  },
  {
    label: 'txtSpanish',
    value: 'es',
  },
  {
    label: 'txtSwedish',
    value: 'sv',
  },
]

export const B4StdHeaderTenant = ({tenant, multipleTenants = false}) => {
  const genTenantTag = () => (
    <div className="flex space-x-b4-std-1/2 items-center">
      <B4TextMedium color={B4Color.BLUE_DARK}>{
        tenant.image ?
          <img className="h-9 object-contain" src={tenant.image} alt="" /> :
          tenant.about_heading
      }</B4TextMedium>
    </div>
  )
  return !tenant ? null : (
    multipleTenants ?
      <div className="px-b4-std"><B4ButtonOutlined color={B4Color.BLUE_DARK} icon={<MdOutlineExpandMore className="text-xl"/>} border={B4ButtonOutlinedBorder.BOTTOM}>
        {genTenantTag()}
      </B4ButtonOutlined></div> :
      genTenantTag()
  )
}

interface FeedbackForm {
  feedback: string
}

const FeedbackModal = ({open, onFinished}) => {
  const { t } = useTranslation()
  const { handleSubmit, control, reset } = useForm<FeedbackForm>()
  const [state, setState] = useState<B4ModalCreateState>(B4ModalCreateState.IDLE)
  const [sendFeedback, {loading}] = useSendFeedback();

  const onSubmit: SubmitHandler<FeedbackForm> = async data => {
    await sendFeedback({ variables: data });
    setState(B4ModalCreateState.CONFIRM)
  }

  useEffect(() => {
    if (open) reset()
    setState(open ? B4ModalCreateState.CREATE : B4ModalCreateState.IDLE)
  }, [open, reset])
  
  return (
    <B4ModalCreate
      state={state}
      onCancel={() => onFinished(false)}
      onSave={() => handleSubmit(onSubmit)()}
      onConfirm={() => onFinished(true)}
      confirmTitle={t('txtYourFeedbackCounts')}
      confirmHtml={t('reportUpdateText')}
      confirmLblOk={t('txtContinue')}
      title={t('txtGiveFeedback')}
      loading={loading}
    >
      <B4Text>{t('txtGiveFeedbackText')}</B4Text>
      <B4ControllerTextInput autoFocus name="feedback" label={t('txtYourFeedbackCounts')} placeholder={t('txtIThinkThatCitizentalkIs')} required control={control} multiline max={300} />
    </B4ModalCreate>
  )
}

export const B4StdNavItem = ({children, onClick, active = false, icon = null, borderBig = false}) => (
  <B4NavItem onClick={onClick} active={active} borderBig={borderBig}>
    <div className="flex items-center">
      {icon && <div className="pr-2 text-lg">{icon}</div>}
      {children}
    </div>
  </B4NavItem>
)

export const B4StdNavHeaderWrapper = ({children = null, onClick, onHomeClick}: {children?: React.ReactNode, onClick: () => void, onHomeClick?: () => void}) => (
  <>
    <B4IconButton onClick={onHomeClick || onClick} big={true}>
      { onHomeClick ? <GrHomeRounded className="text-b4-primary" /> : <BiMenuAltLeft className="text-b4-primary" /> }
      
    </B4IconButton>
    <div className="cursor-pointer" onClick={onClick}>
      { children }
    </div>
  </>
)

const B4StdHeaderNavigation = ({home = false}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const logout = useLogout()
  const { data: {me} = {} } = useMe({})
  const { data: {tenants: adminTenants} = {} } = useTenants({skip: me?.type !== 'Admin'})

  const nonExpiredTenants = (adminTenants || me?.tenants)?.filter(tenant => !tenant.expired)

  const tenantActive = useTenant()

  const [openNav, setOpenNav] = useState(false)
  const [openFeedback, setOpenFeedback] = useState(false)

  const onClick = (op: () => void): (() => void) => () => {
    setOpenNav(false)
    op()
  }
  
  const changeTenant = (tenant) => {
    navigate(`/room/${tenant.id}`)
  }

  return (
    <>
      <B4Navigation open={openNav} onClose={() => setOpenNav(false)}>{
        nonExpiredTenants && sortBy(nonExpiredTenants, 'about_heading').map((tenant, i) => (
          <B4StdNavItem key={i} borderBig={i === nonExpiredTenants.length - 1}
            onClick={() => changeTenant(tenant)}
            active={tenant.id === tenantActive?.id}><span className={clsx({
              'font-bold text-b4-title': tenant.id === tenantActive?.id
            })}>{
            tenant.about_heading
          }</span></B4StdNavItem>
        ))}
        <B4StdNavItem icon={<BsMegaphoneFill/>} onClick={onClick(() => setOpenFeedback(true))}>{t('txtGiveFeedback')}</B4StdNavItem>
        <B4StdNavItem icon={<MdSettings/>} onClick={onClick(() => navigate('/settings'))}>{t('txtSettings')}</B4StdNavItem>
        <B4StdNavItem icon={<MdLogout/>} onClick={onClick(() => logout())}>{t('txtLogout')}</B4StdNavItem>
      </B4Navigation>
      <B4StdNavHeaderWrapper onClick={() => setOpenNav(true)} onHomeClick={home ? () => navigate('/') : null}>
        <B4StdHeaderTenant tenant={tenantActive} multipleTenants={nonExpiredTenants?.length > 1} />
      </B4StdNavHeaderWrapper>
      <FeedbackModal open={openFeedback} onFinished={() => setOpenFeedback(false)} />
    </>
  )
}

const B4StdHeaderTranslation = () => {
  const { t } = useTranslation()
  const [updateUser] = useUpdateUser()
  const { data: dataMe } = useMe()
  const client = useApolloClient()

  const switchTranslation = async () => {
    await updateUser({
      variables: {
        translation_enabled: !dataMe.me.translation_enabled
      },
    });
    client.resetStore().then(_ => {});
  }

  return (
    <B4NavItem onClick={() => switchTranslation()}><B4SpaceHorizontal className="flex items-center"><div className="text-xl">{dataMe?.me?.translation_enabled ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}</div><div>{t('txtTranslation')}</div></B4SpaceHorizontal></B4NavItem>
  )
}

export const B4LanguageButton = ({ isAuthorized = true }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const [updateUser] = useUpdateUser()
  const client = useApolloClient()

  const B4StdLangItem = ({ children, onClick, active = false, borderBig = false }) => (
    <B4NavItem onClick={() => {
      setOpen(false)
      onClick()
    }} active={active} borderBig={borderBig}><span className={clsx({
      'font-bold text-b4-title': active
    })}>{children}</span></B4NavItem>
  )

  const changeLanguage = async (language: string) => {
    if (isAuthorized) {
      await updateUser({
        variables: {
          language
        },
      });
    }
    window.location.reload() // hard reload of the page because otherwise, wrong language part might be in use
  }

  return (<div>
    <B4Navigation open={open} onClose={() => setOpen(false)} header={<B4TextMedium>{t('txtLanguage')}</B4TextMedium>}>{
      LANGUAGES.map(({ label, value }, i) => (
        <B4StdLangItem key={value} onClick={() => changeLanguage(value)} active={i18n.language === value} borderBig={i === LANGUAGES.length - 1}>{t(label)}</B4StdLangItem>
      ))}
      {isAuthorized && <B4StdHeaderTranslation />}
    </B4Navigation>

    <B4ButtonOutlined onClick={() => setOpen(true)} color={B4Color.BLUE_DARK} icon={<TfiWorld  />}>
      <B4TextTiny className="uppercase text-nowrap" color={B4Color.BLUE_DARK}>{i18n.language}</B4TextTiny>
    </B4ButtonOutlined>
  </div>)
}

export const B4StdHeaderBtns = ({children = null, isAuthorized = true}) => (
  <div className="flex space-x-b4-std-1/2">
    {children}
    <B4LanguageButton isAuthorized={isAuthorized} />
  </div>
)

export const B4StdHeader = ({tenant = null, btns = null, home = false}) => {
  const { loading: loadingAuth, data: dataAuth } = useAuth()

  const isAuthorized = !loadingAuth && dataAuth?.isLoggedIn
  
  return (
    <B4Header>
      { (isAuthorized && <B4StdHeaderNavigation home={home} />) ||
        (tenant && <B4StdHeaderTenant tenant={tenant} />) ||
        <div/>
      }
      <B4StdHeaderBtns isAuthorized={isAuthorized}>{btns}</B4StdHeaderBtns>
    </B4Header>
  )
}

export const B4PoweredBy = ({children = null, className = null, ruler = true}) => {
  const { t } = useTranslation()
  return (
    <B4SpaceVertical className={className}>
      {ruler && <div className="py-b4-std"><B4RulerHorizontal/></div> }
      <div className="space-y-1">
        <B4TextTiny className='text-center' html={t('txtPoweredBy')} />
        <div className='flex justify-center'>
          <img className="max-w-full w-24" src={logo} alt='' />
        </div>
      </div>
      {children}
    </B4SpaceVertical>
  )
}