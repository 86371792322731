import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const VOTE_AND_GET_NEXT_PROMPT = gql`
  mutation ($questionId: ID!, $promptId: ID!, $choiceId: ID!) {
    VoteAndGetNextPrompt(
      input: {
        question_id: $questionId
        prompt_id: $promptId
        choice_id: $choiceId
      }
    ) {
      prompt {
        prompt_id
        question_id
        left_choice_id
        right_choice_id
        left_choice_text
        right_choice_text
        left_choice_reported
        right_choice_reported
        left_choice_mine
        right_choice_mine
      }
    }
  }
`;

const useVoteAndGetNextPrompt = () => {
  return useMutation(VOTE_AND_GET_NEXT_PROMPT);
};

export default useVoteAndGetNextPrompt;
