import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const SKIP_AND_GET_NEXT_PROMPT = gql`
  mutation ($questionId: ID!, $promptId: ID!, $bothGood: Boolean!) {
    SkipAndGetNextPrompt(
      input: { question_id: $questionId, prompt_id: $promptId, both_good: $bothGood }
    ) {
      prompt {
        prompt_id
        question_id
        left_choice_id
        right_choice_id
        left_choice_text
        right_choice_text
        left_choice_reported
        right_choice_reported
        left_choice_mine
        right_choice_mine
      }
    }
  }
`;

const useSkipAndGetNextPrompt = () => {
  return useMutation(SKIP_AND_GET_NEXT_PROMPT);
};

export default useSkipAndGetNextPrompt;
