import { useQuery, gql } from '@apollo/client';

export const INNOVATOR_STATS_QUERY = gql`
  query ($conversation_id: ID!) {
    GetInnovatorStats(id: $conversation_id) {
      prompts_done
      prompts_remaining
      prompts_total
      votes_total
    }
  }
`;

const useInnovatorStats = (options = {}) => {
  return useQuery(INNOVATOR_STATS_QUERY, options);
};

export default useInnovatorStats;
