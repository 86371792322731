import { useNavigate } from 'react-router-dom';
import { InviteCodeForm } from './components/invite-code-popup';
import { saveRoom } from '../../utils';
import { B4LoginLayout } from '../common/login';

const InviteCode = () => {
  const navigate = useNavigate();

  return (

    <B4LoginLayout>
      <InviteCodeForm successCallback={id => {
        saveRoom(id);
        navigate('/landing');
      }} />
    </B4LoginLayout>
  );
};

export default InviteCode;