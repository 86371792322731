import { useQuery, gql } from '@apollo/client';

const AUTH_CHECK = gql`
  query {
    isLoggedIn @client(always: true)
  }
`;

interface AuthCheckData {
  isLoggedIn: boolean;
}

const useAuth = () => {
  return useQuery<AuthCheckData, {}>(AUTH_CHECK, {
    fetchPolicy: 'no-cache',
  });
};

export default useAuth;
