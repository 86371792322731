import { gql, useMutation } from '@apollo/client';

const SEND_FEEDBACK_MUTATION = gql`
  mutation($feedback: String!) {
    sendFeedback(feedback: $feedback) {
      id
      feedback
    }
  }
`;

export const useSendFeedback = () => {
  return useMutation(SEND_FEEDBACK_MUTATION);
};
