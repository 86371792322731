import { B4Layout, B4SpaceVertical } from '../../design/atomic/layout';
import { B4Above } from '../../design/atomic/modal';
import { useEffect, useState } from 'react';
import { B4Switcher } from '../../design/atomic/switcher';
import useConversations from '../../hooks/queries/useConversations';
import { useMe } from '../../hooks';
import { B4HeaderLight } from '../../design/atomic/header-footer';
import clsx from 'clsx';
import { B4Bubble } from '../../design/atomic/bubble';
import { B4Text } from '../../design/atomic/text';
import { orderBy } from 'lodash';

const VotingWrapperInner = ({children, conversation, above}) => {
  const card = <B4Bubble><B4Text html={conversation.question}/></B4Bubble>
  return (
  <B4SpaceVertical className="grow flex flex-col">
    { above ? <B4Above>{card}</B4Above> : card }
    { children }
  </B4SpaceVertical>
)}

const VotingWrapperSwitcher = ({disabled, conversation, onConversationSwitch, children, above}) => {
  const { data: {conversations} = {} } = useConversations({
    variables: {
      mine: false,
      clase: false
    },
  })
  const { data: { me } = {} } = useMe()

  const [conversationsFiltered, setConversationsFiltered] = useState([])
  const [currentIndex, setCurrentIndex] = useState<number>(-1)

  useEffect(() => {
    if (conversations && conversation && me) {
      const filtered = orderBy(conversations.filter(c => c?.user?.id !== me?.id && !c.ended_or_expired), ['seq_nr'], ['asc'])
      const currentIndex = filtered?.findIndex(c => c.id === conversation?.id)
      setCurrentIndex(currentIndex)
      setConversationsFiltered(filtered)
    }
  }, [conversation, conversations, me])

  return currentIndex >= 0 ? (
    <B4Switcher disabled={disabled} innerClassName='grow' onChange={newIndex => onConversationSwitch(conversationsFiltered[newIndex])} elements={conversationsFiltered?.map((c, i) =>
      <VotingWrapperInner above={above} conversation={c}>{ currentIndex === i && children }</VotingWrapperInner>
    )} current={currentIndex} />
  ) : null
}


const VotingWrapper = ({loading = false, conversation, children, headerMiddle = null, headerBtns = null, onConversationSwitch = null, disableSwitcher = false, footer = null, isConversationAbove = false, hFull = false}) => {
  return (<>
    <B4Layout header={<B4HeaderLight headerBtns={headerBtns}>{headerMiddle}</B4HeaderLight>}>
      <div className="h-full flex flex-col justify-center">
      <div className={clsx('flex flex-col h-full', {
        'max-h-[50rem]': !hFull
      })}>
        <B4SpaceVertical className="flex flex-col h-full grow">
          {!loading && <>{
            (onConversationSwitch) ? 
            ( <VotingWrapperSwitcher above={isConversationAbove} disabled={disableSwitcher} conversation={conversation} onConversationSwitch={onConversationSwitch}>{children}</VotingWrapperSwitcher> ) :
            (
              <VotingWrapperInner above={isConversationAbove} conversation={conversation}>{children}</VotingWrapperInner>
            )
          }</>}
        </B4SpaceVertical>
        { footer }
      </div>
      </div>
    </B4Layout>
  </>);
};

export default VotingWrapper;
