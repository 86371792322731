import { useApolloClient, useMutation } from '@apollo/client';
import { ME_QUERY } from '../../../../hooks/queries/useMe';
import { removeAuth } from '../../../../utils';
import { UPDATE_USER, DELETE_ACCOUNT } from './mutations';

export const useLogout = () => {
  const client = useApolloClient();

  return () => {
    removeAuth();
    client.cache.reset();
    window.location.pathname = '/'
  };
};

export const useUpdateUser = () => {
  return useMutation(UPDATE_USER, {
    update(cache: any, { data: updateUser }) {
      // Invalidate all coaches overview data
      Object.keys(cache.data.data.ROOT_QUERY).forEach(key => {
        if (key.match(/users/)) {
          delete cache.data.data.ROOT_QUERY[key];
        }
      });

      // Update me data
      const { me } = cache.readQuery({ query: ME_QUERY }) || {};

      if (!me) return;

      const updatedMe = {
        ...me,
        ...updateUser,
      };

      cache.writeQuery({
        query: ME_QUERY,
        data: { me: updatedMe },
      });
    },
  });
};

export const useDeleteAccount = () => {
  const logout = useLogout();
  return useMutation(DELETE_ACCOUNT, {
    onCompleted: () => {
      logout();
    },
  });
};
