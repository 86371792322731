export enum B4Color {
    BLUE_DARK,
    BLUE,
    BLUE_LIGHT,
    GREEN,
    GREEN_NEON,
    RED,
    WHITE,
    GREY,
    ORANGE
  }