import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { ME_QUERY } from '../queries/useMe';

const ANSWER_CREATE = gql`
  mutation CreateAnswer($answer: String!, $conversation: ID!, $tenant_id: ID) {
    CreateAnswer(answer: $answer, conversation_id: $conversation, tenant_id: $tenant_id) {
      id,
      answer,
    }
  }
`;

const useCreateAnswer = () => {
  return useMutation(ANSWER_CREATE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ME_QUERY,
      }
    ],
  });
};

export default useCreateAnswer;
