import React from 'react';
import {useTranslation} from 'react-i18next';
import VotingWrapper from './votingWrapper';
import { useParams } from 'react-router-dom';
import { useInnovatorStats, useResonance, useTenantMinimal } from '../../hooks';
import { MdHourglassTop, MdShare } from 'react-icons/md';
import useConversation from '../../hooks/queries/useConversation';
import { B4Text, B4TextEmp, B4TextMedium, B4TextSmall } from '../../design/atomic/text';
import { B4SpaceHorizontal, B4SpaceVertical } from '../../design/atomic/layout';
import { B4Bubble, B4Corner } from '../../design/atomic/bubble';
import { B4HeaderSub, B4HeaderHelp, B4HeaderBackBtn } from '../../design/atomic/header-footer';
import { prefixRoomLink } from '../../utils';
import { B4ButtonOutlined } from '../../design/atomic/button';
import { genVotingProgressLevel } from './votingProgress';
import { formatNumber } from '../../design/atomic/utils';
import { B4Color } from '../../design/atomic/consts';
import useUserVotingStats from '../../hooks/queries/useUserVotingStats';

const WAITING_PLACEHOLDER = <div className='flex justify-center text-b4-secondary text-2xl'><MdHourglassTop/></div>

const VotingStats = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const tenant = useTenantMinimal();

  // optimize this query. T
  const { loading: loadingConversation, data: {conversation} = {} } = useConversation({
    variables: { conversation_id: id },
    notifyOnNetworkStatusChange: true,
  });

  const { data: { userVotingStats: dataUserVotingStats } = {}, loading: loadingUserVotingStats } = useUserVotingStats({
    variables: { conversation_id: id },
    fetchPolicy: 'cache-and-network',
  });

  const allLoading = loadingConversation || loadingUserVotingStats;

  const shareQuestion = async() => {
    const shareData = {
      title: tenant?.about_heading,
      text: conversation.question,
      url: window.location.origin + prefixRoomLink(`/rate/question/${conversation.id}/rate`)
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      // sharing failed, we ignore this
    }
  }

  return (
    <VotingWrapper
      loading={allLoading}
      conversation={conversation}
      headerBtns={<>
        <B4HeaderHelp html={t('txtMyIdeasHelp')}/>
        {navigator.share && <B4ButtonOutlined color={B4Color.BLUE_DARK} onClick={shareQuestion} full={false}><MdShare /></B4ButtonOutlined>}
      </>}
      headerMiddle={<B4HeaderBackBtn/>}
      hFull
    >
      <B4SpaceVertical>
        { (dataUserVotingStats?.battles) ? <>
          <B4TextEmp className="text-center" color={B4Color.GREEN} html={t('txtReachedLevel', {level: genVotingProgressLevel(dataUserVotingStats?.battles)})} />
          <B4Text color={B4Color.WHITE} className='text-center'>{formatNumber(dataUserVotingStats?.battles)} {t('txtBattles')} / {formatNumber(dataUserVotingStats?.userAnswers.length)} {t('lblIdeas')}</B4Text>
        </> : null}
        { dataUserVotingStats?.userAnswers.length ? <>
          <B4HeaderSub 
            middle={<B4TextEmp className='text-center' color={B4Color.GREEN}>{t('lblMyIdeas')}</B4TextEmp>}
          />
          {
            dataUserVotingStats?.userAnswers.map((answer, i) => (
              <B4Bubble color={B4Color.GREEN_NEON} key={i} position={i + 1} sharpCorner={
                (i % 3 === 1 && B4Corner.BOTTOM_LEFT) ||
                (i % 3 === 2 && B4Corner.TOP_RIGHT) ||
                B4Corner.TOP_LEFT
              }>
                <B4SpaceHorizontal className="flex items-center">
                  <div className='flex flex-col items-center'>{
                    answer.rank ? <>
                      <B4TextSmall color={B4Color.WHITE}>{t('txtRank')}</B4TextSmall>
                      <B4TextMedium color={B4Color.WHITE}>{answer.rank}</B4TextMedium>
                    </> : 
                    <B4Text color={B4Color.WHITE}><MdHourglassTop/></B4Text>
                  }</div>
                  <B4Text color={B4Color.WHITE} className="font-bold">{answer.answer}</B4Text>
                </B4SpaceHorizontal>
              </B4Bubble>
            ))}
          </> : null}
        <B4HeaderSub 
          middle={<B4TextEmp className='text-center' color={B4Color.GREEN}>{t('lblMyBattles')}</B4TextEmp>}
        />
        { !dataUserVotingStats?.userRanking.length && WAITING_PLACEHOLDER }
        {
          dataUserVotingStats?.userRanking && dataUserVotingStats?.userRanking
          .map((answer, i) => (
            <B4Bubble color={B4Color.BLUE} key={i} position={i} sharpCorner={
              (i % 3 === 1 && B4Corner.BOTTOM_LEFT) ||
              (i % 3 === 2 && B4Corner.TOP_RIGHT) ||
              B4Corner.TOP_LEFT
            }>
              <B4SpaceHorizontal className="flex items-center">
                <B4TextMedium noWordBreak color={B4Color.WHITE}>{answer.rank}</B4TextMedium>
                <div>{answer.answer}</div>
              </B4SpaceHorizontal>
            </B4Bubble>
          ))
        }
      </B4SpaceVertical>
    </VotingWrapper>
  )
}

export default VotingStats;
