import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const REGISTER_INVITE_CODE = gql`
  mutation($invite_code: String!) {
    registerInviteCode(invite_code: $invite_code) {
      id
    }
  }
`;

const useRegisterInviteCode = () => {
  return useMutation(REGISTER_INVITE_CODE);
};

export default useRegisterInviteCode;
