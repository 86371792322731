import { useQuery, gql } from '@apollo/client';

export const SEARCH_TOWNS_QUERY = gql`
  query($name: Mixed!, $code: Mixed!) {
    searchTowns(where: {
      OR: [
        {column:NAME, operator: LIKE, value: $name},
        {column:CODE, operator: LIKE, value: $code},
      ]
    }) {
      id
      code
      name
    }
  }
`;

const useSearchTowns = (options = {}, searchStr) => {
  const mutatedOptions = {
    ...options,
    variables: {
      name: `${searchStr}%`,
      code: `${searchStr}%`
    }
  }
  return useQuery(SEARCH_TOWNS_QUERY, mutatedOptions);
};

export default useSearchTowns;
