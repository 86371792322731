import Linkify from 'react-linkify';
import linkifyHtml from 'linkify-html';

const CtLinkify = props => (
	<Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
		<a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
			{decoratedText}
		</a>)}>
		{props.children}
	</Linkify>);

export default CtLinkify;

export const ctLinkifyText = (text) => text ? linkifyHtml(text) : text