import { useQuery} from '@apollo/client';
import { graphql } from '../../gql';

const USER_VOTING_STATS_QUERY = graphql(/* GraphQL */ `
  query userVotingStats(
    $conversation_id: ID!
  ) {
    userVotingStats(conversation_id: $conversation_id) {
      battles
      userAnswers {
        answer
        rank
      }
      userRanking {
        answer
        rank
      }
    }
  }
`)

const useUserVotingStats = (options = {}) => useQuery(USER_VOTING_STATS_QUERY, options);

export default useUserVotingStats;
