import { LanguageDetectorAsyncModule } from 'i18next';

const key = 'i18nextLng';

const LanguageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: callback => {
    callback(localStorage.getItem(key) || 'de')
  },
  init: () => {},
  cacheUserLanguage: value => {
    localStorage.setItem(key, value);
  },
};

export default LanguageDetector;
