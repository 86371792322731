import { endsWith, isString } from "lodash";
import { Location, useNavigate } from "react-router";
import { prefixRoomLink } from "./auth";

const INITIALLY_REQUESTED_LOCATION = 'initially-requested-location';

const getPath = () : string => localStorage.getItem(INITIALLY_REQUESTED_LOCATION) || prefixRoomLink('/dashboard');

const cleanup = () => localStorage.removeItem(INITIALLY_REQUESTED_LOCATION)

const getRequestedPathAndCleanup = () : string => {
	const path = getPath();
	cleanup();
	return path;
}

export const storeRequestedLocation = (locationOrPath: Location | string) => {
	const path = isString(locationOrPath) ? locationOrPath : locationOrPath.pathname;
	if (path !== '/' && !endsWith(path, "pick-room")) {
		localStorage.setItem(INITIALLY_REQUESTED_LOCATION, path);
	}
}

export const useRedirectAfterFinished = () => {
	const navigate = useNavigate()
	return () => {
		setTimeout(() => navigate(getRequestedPathAndCleanup()));
	}
}