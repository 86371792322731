import { lazy } from 'react';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import InviteCode from '../../components/landing/invite-code';
import EmailSignup from '../../components/signup/email-signup';
import VotingStats from '../../components/voting/votingStats';
import { BackgroundDarkWrapper, GuestRoute, RoomRoute, UserRoute, GuestRouteIgnoreLoggedIn } from './routeHelpers';

const Dashboard = lazy(() => import('../../components/screens/overview'));
const Settings = lazy(() => import('../../components/screens/profile/settings'));
const FurtherFeatures = lazy(() => import('../../components/screens/profile/further-features'));
const CompleteProfile = lazy(() => import('../../components/signup/complete'));
const Landing = lazy(() => import('../../components/landing'));
const Unsubscribe = lazy(() => import('../../components/landing/unsubscribe'));
const Login = lazy(() => import('../../components/login'));
const Signup = lazy(() => import('../../components/signup'));
const Voting = lazy(() => import('../../components/voting/voting'));
const Countdown = lazy(() => import('../../components/voting/countdown'));
const ForgotPassword = lazy(() => import('../../components/forgot-password'));
const UserRoomWrapper = lazy(() => import('../../components/room/user-room-wrapper'));
const RoomPicker = lazy(() => import('../../components/room/room-picker'));
const TenantUpsert = lazy(() => import('../../components/admin/tenant-upsert'));
const TenantSettings = lazy(() => import('../../components/admin/tenant-settings'));
const TenantStats = lazy(() => import('../../components/admin/tenant-stats'));
const ConversationUpsert = lazy(() => import('../../components/admin/conversation-upsert'));
const ConversationList = lazy(() => import('../../components/admin/conversation-list'));
const IdeaSeedCreate = lazy(() => import('../../components/admin/idea-seed-create'));
const IdeaDashboard = lazy(() => import('../../components/admin/idea-dashboard'));
const IdeaList = lazy(() => import('../../components/admin/idea-list'));
const ConversationDashboard = lazy(() => import('../../components/admin/conversation-dashboard'));
const ConversationKPIs = lazy(() => import('../../components/admin/conversation-kpis'));
const ConversationSwitcher = lazy(() => import('../../components/admin/conversation-switcher'));


const Routing = () => {
  return (
    <Routes>
      <Route element={<BackgroundDarkWrapper swarm={true}/>}>
        <Route path='/landing' element={<GuestRoute><Landing /></GuestRoute>} />
        <Route path='/unsubscribe' element={<GuestRouteIgnoreLoggedIn><Unsubscribe /></GuestRouteIgnoreLoggedIn>} />
        <Route path='/forgot-password' element={<GuestRoute><ForgotPassword /></GuestRoute>} />
        <Route path='/invite-code' element={<GuestRoute><InviteCode /></GuestRoute>} />
        <Route path='/register/otp' element={<GuestRoute><EmailSignup /></GuestRoute>} />
        <Route path='/register/guest' element={<RoomRoute><Signup method={'guest'} /></RoomRoute>} />
        <Route path='/register/email_otp' element={<RoomRoute><Signup method={'email_otp'} /></RoomRoute>} />
        <Route path='/register/email' element={<RoomRoute><Signup method={'email'} /></RoomRoute>} />
        <Route path='/login' element={<GuestRoute><Login /></GuestRoute>} />
        <Route path='/pick-room/no-auto' element={<UserRoute><RoomPicker auto={false} /></UserRoute>} />
        <Route path='/pick-room' element={<UserRoute><RoomPicker /></UserRoute>} />
        <Route path='/settings' element={<UserRoute><Settings /></UserRoute>} />
        <Route path='/further-features' element={<UserRoute><FurtherFeatures /></UserRoute>} />
        <Route path='/room'>
          <Route path='create' element={<UserRoute><TenantUpsert/></UserRoute>} />
          <Route path=':roomId' element={<UserRoomWrapper />}>
            <Route path='complete' element={<CompleteProfile />} />
            <Route path='complete/innovator' element={<CompleteProfile innovator={true} />} />
            <Route path='rate/question/:id/vote' element={<Voting />} />
            <Route path='rate/question/:id/rate' element={<Countdown />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='questions' element={<Dashboard doInitialScroll={true} />} />
            <Route path='rate/question/:id/stats' element={<VotingStats />} />


            {/* admin routes */}
            <Route path='edit' element={<TenantUpsert/>} />
            <Route path='settings' element={<TenantSettings />} />
            <Route path='stats' element={<TenantStats />} />
            <Route path='conversation' element={<ConversationList/>} />
            <Route path='conversation/create' element={<ConversationUpsert/>} />
            <Route path='conversation/:conversationId/edit' element={<ConversationUpsert/>} />
            <Route path='conversation/:conversationId' element={<ConversationSwitcher />}>
              <Route path='dashboard' element={<ConversationDashboard/>} />
              <Route path='kpis' element={<ConversationKPIs/>} />
              <Route path='idea' element={<IdeaList/>} />
              <Route path='seed-idea' element={<IdeaList isSeed/>} />
            </Route>
            <Route path='conversation/:conversationId/idea/create' element={<IdeaSeedCreate/>} />
            <Route path='conversation/:conversationId/idea/:answerId' element={<IdeaDashboard/>} />
            <Route path='' element={<Navigate to="dashboard" />} />
            <Route path='*' element={<Navigate to="dashboard" />} />
          </Route>
        </Route>
      </Route>

      <Route path='*' element={<UserRoute><Navigate to='/pick-room' /></UserRoute>} />
    </Routes>
  );
};

const BrowserRouting = () => (
  <BrowserRouter>
    <Routing />
  </BrowserRouter>
);

export default BrowserRouting;
