interface User {
  name: string;
  email: string;
}

const analytics = {
  init: () => {
    // if (process && process.env && process.env.REACT_APP_LOGROCKET_PROJECT_ID) {
    //   const { REACT_APP_LOGROCKET_PROJECT_ID } = process.env;

    //   if (REACT_APP_LOGROCKET_PROJECT_ID !== "") {
    //     import("logrocket").then(lr => {
    //       lr.default.init(REACT_APP_LOGROCKET_PROJECT_ID, {
    //         dom: {
    //           baseHref: 'https://app.citizentalk.ch/',
    //         },
    //       });
    //       import("logrocket-react").then(lrr => {
    //         lrr.default(lr);
    //         console.info('LogRocket initialized');
    //       });
    //     });
    //   }
    // }
  },
  identify: (id: string, user: User) => {
    // if (process && process.env && process.env.REACT_APP_LOGROCKET_PROJECT_ID) {
    //   const { REACT_APP_LOGROCKET_PROJECT_ID } = process.env;

    //   if (REACT_APP_LOGROCKET_PROJECT_ID !== "") {
    //     import("logrocket").then(lr => {
    //       lr.default.identify(id, {
    //         name: user.name,
    //         email: user.email,
    //       });
    //     });
    //   }
    // }
  },
  track: (event: string, options = {}) => {
    // if (process && process.env && process.env.REACT_APP_LOGROCKET_PROJECT_ID) {
    //   const { REACT_APP_LOGROCKET_PROJECT_ID } = process.env;

    //   if (REACT_APP_LOGROCKET_PROJECT_ID !== "") {
    //     import("logrocket").then(lr => {
    //       lr.default.track(event);
    //     });
    //   }
    // }
  },
};

export default analytics;
