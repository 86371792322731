import { useQuery, gql } from '@apollo/client';

const COUNTRIES_QUERY = gql`
  query {
    countries {
      id
      code
    }
  }
`;

const useCountries = () => {
  return useQuery(COUNTRIES_QUERY);
};

export default useCountries;
