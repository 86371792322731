import { NavigateOptions, To, useLocation, useNavigate } from "react-router";

interface Auth {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
}

export const saveAuth = (auth: Auth): void => {
  localStorage.setItem('access_token', auth.access_token);
  localStorage.setItem('refresh_token', auth.refresh_token);
  localStorage.setItem('token_type', auth.token_type);
  localStorage.setItem('expires_in', auth.expires_in);
};

export const removeAuth = (): void => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('token_type');
  localStorage.removeItem('expires_in');
};

export const getAuth = (): Auth => ({
  access_token: localStorage.getItem('access_token') || '',
  refresh_token: localStorage.getItem('refresh_token') || '',
  token_type: localStorage.getItem('token_type') || '',
  expires_in: localStorage.getItem('expires_in') || '',
});


let currentRoom: string = null
let inviteCode: string = null

export const saveRoom = (id: string): void => {
  currentRoom = id
}

export const removeRoom = (): void => {
  currentRoom = null
}

export const getRoom = (): string | null => currentRoom;

interface RoomNavigateOptions {
  keepSearch?: boolean;
}

interface RoomNavigateFunction {
  (to: To, options?: NavigateOptions, roomOptions?: RoomNavigateOptions): void;
  (delta: number): void;
}

export const useRoomNavigate = (): RoomNavigateFunction => {
  const navigate = useNavigate()
  const location = useLocation()

  return (to: To | number, options: NavigateOptions = {}, roomOptions: RoomNavigateOptions = {}) => {
    const currentRoom = getRoom()
    if (typeof to === 'string' && currentRoom) {
      to = `/room/${currentRoom}${to}${roomOptions.keepSearch ? location.search : ''}`
    }
    if (typeof to === 'number') {
      return navigate(to);
    }
    return navigate(to, options)
  }
}

export const prefixRoomLink = (link: string): string => currentRoom ? `/room/${getRoom()}${link}` : link

export const saveInviteCode = (code: string): void => {
  inviteCode = code
}

export const getInviteCode = (): string => inviteCode

export const clearInviteCode = () => {
  inviteCode = null
}