import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {REFETCH_CONVERSATIONS} from './refetches';

const QUESTION_UPDATE = gql`
  mutation($id: ID!, $question: String!, $start_at: Date, $end_at: Date, $user_id: ID!) {
    updateConversation(id: $id, question: $question, start_at: $start_at, end_at: $end_at,user_id: $user_id) {
      id,
      question,
      start_at,
      end_at,
      user {
        id
      }
    }
  }
`;

const useUpdateQuestion = () => {
  return useMutation(QUESTION_UPDATE, {
    awaitRefetchQueries: true,
    refetchQueries: REFETCH_CONVERSATIONS
  });
};

export default useUpdateQuestion;
