import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {REFETCH_CONVERSATIONS} from './refetches';

const QUESTION_DELETE = gql`
  mutation($id: ID!) {
    deleteConversation(id: $id) {
      id,
      deleted_at
    }
  }
`;

const useDeleteQuestion = () => {
  return useMutation(QUESTION_DELETE, {
    awaitRefetchQueries: true,
    refetchQueries: REFETCH_CONVERSATIONS,
  });
};

export default useDeleteQuestion;
