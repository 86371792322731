import { useQuery } from '@apollo/client';
import { graphql } from '../../gql';

export const TENANTS_QUERY = graphql(/* GraphQL */ `
  query tenants($tenant_id: ID) {
    tenants(id: $tenant_id) {
      id
      image
      about_heading
      about_body
      teaser
      customer_name
      invite_code
      quality_control
      contributer_count
      expired
      login_methods
      mandatory_user_info
      language
      expire_at
      notifications_enabled
      admins {
        id
        email
      }
      super_admins {
        id
        email
      }
      conversations {
        id
        question
      }
      ranking_min_battles
      ranking_min_participants
      target_group_size
      translation_blacklist
    }
  }
`)

const useTenants = (options = {}) => {
  return useQuery(TENANTS_QUERY, options);
};

export default useTenants;
