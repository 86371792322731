import { gql, useMutation } from '@apollo/client';

const DELETE_ANSWER = gql`
  mutation deleteAnswer($id: ID!) {
    deleteAnswer(id: $id) {
      id
    }
  }
`;

const useDeleteAnswer = () => {
  return useMutation(DELETE_ANSWER, {
    update(cache, { data: { deleteAnswer } }) {
      cache.evict({
        id: cache.identify(deleteAnswer),
      });
      cache.gc();
    },
  });
};

export default useDeleteAnswer;