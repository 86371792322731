import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

export const CURRENT_TENANT_QUERY = gql`
  query {
    currentTenant {
      id
      image
      about_heading
      about_body
      invite_code
      quality_control
      contributer_count
      expired
      expire_at
    }
  }
`;

const useTenant = () => {
  const { data } = useQuery(CURRENT_TENANT_QUERY);
  const tenant = useMemo(() => {
    if (!data) return {};
    return data.currentTenant;
  }, [data]);

  return tenant;
};

export default useTenant;
