import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { validateInviteCode } from '../../utils/inviteCode';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { saveAuth, saveRoom, useRoomNavigate } from '../../utils';
import { storeRequestedLocation, useRedirectAfterFinished } from '../../utils/locationRestore';
import { B4StdHeader } from '../common/standard';
import { B4Layout } from '../../design/atomic/layout';

const ME_FRAGMENT = gql`
  fragment EmailSignupMeFragment on User {
    id
    profile_complete
    language
  }
`;
const LOGIN_OR_REGISTER_WITH_OTP_MUTATION = gql`
  mutation ($otp: String!) {
    loginOrRegisterWithOtp(
      otp: $otp
    ) {
        access_token
        refresh_token
        token_type
        expires_in
        user {
          ...EmailSignupMeFragment
        }
      }
    }
    ${ME_FRAGMENT}
`;

const EmailSignup = () => {
  const navigate = useNavigate();
  const roomNavigate = useRoomNavigate();
  const [searchParams] = useSearchParams();
  const otp = searchParams.get('otp');
  const inviteCode = searchParams.get('code');
  const targetLocation = searchParams.get('target_location');
  const client = useApolloClient();
  const redirectAfterFinished = useRedirectAfterFinished();

  if (!otp) {
    navigate('/landing');
  }

  if (targetLocation) {
    storeRequestedLocation(targetLocation);
  }

  const [loginOrRegisterWithOtp] = useMutation(LOGIN_OR_REGISTER_WITH_OTP_MUTATION, {
    onCompleted: async ({ loginOrRegisterWithOtp }) => {
      saveAuth(loginOrRegisterWithOtp);
      if (loginOrRegisterWithOtp.user.profile_complete) {
        redirectAfterFinished();
      } else {
        roomNavigate('/complete');
      }
    },
    onError: () => {
      navigate('/landing'); // We could add an indication that an error happened
    },
  });

  useEffect(() => {
    const call = async () => {
      if (inviteCode) {
        const tenant = await validateInviteCode(client, inviteCode)
        if (tenant?.id) {
          saveRoom(tenant?.id)
        }
      }
      loginOrRegisterWithOtp({ variables: { otp: otp } });
    };
    call().catch();
  }, [client, inviteCode, loginOrRegisterWithOtp, otp]);

  return (
    <B4Layout header={<B4StdHeader />}>
    </B4Layout>
  );
};

export default EmailSignup;
