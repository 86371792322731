import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {APPROVAL_ANSWER_QUERY} from '../queries/useApprovalAnswers';

const ANSWER_APPROVE = gql`
  mutation approveAnswer($id: ID!) {
    approveAnswer(id: $id) {
      id
      approved
    }
  }
`;

const useApproveAnswer = ({conversationId}) => {
  return useMutation(ANSWER_APPROVE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: APPROVAL_ANSWER_QUERY,
        variables: { conversation_id: conversationId },
      }
    ]
  });
};

export default useApproveAnswer;
