import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const ANSWER_NEXT_STEPS_UPDATE = gql`
  mutation($id: ID!, $next_steps: String) {
    updateAnswer(
      id: $id
      next_steps: $next_steps) {
        id
        next_steps
      }
  }
`;

const useUpdateAnswerNextSteps = () => {
  return useMutation(ANSWER_NEXT_STEPS_UPDATE);
};

export default useUpdateAnswerNextSteps;
