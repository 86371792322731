import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { ME_QUERY } from '../queries/useMe';

const LIKE_ANSWER_MUTATION = gql`
  mutation($answer_id: ID!) {
    likeAnswer(answer_id: $answer_id) {
      success
    }
  }
`;

const useLikeAnswer = ({ variables }) => {
  return useMutation(LIKE_ANSWER_MUTATION, {
    variables,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ME_QUERY,
      },
    ],
  });
};

export default useLikeAnswer;
