import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const UPDATE_TENANT = gql`
  mutation($id: ID!, $about_heading: String, $about_body: String, $quality_control: String, $features: TenantFeaturesInput) {
    updateTenant(
      id: $id
      about_heading: $about_heading
      about_body: $about_body
      quality_control: $quality_control
      features: $features
    ) {
      id
      about_heading
      about_body
      quality_control
      features {
        phone
      }
    }
  }
`;

const useUpdateTenant = () => {
  return useMutation(UPDATE_TENANT);
};

export default useUpdateTenant;
